<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="8">
        <span class="title">资源编号</span>
        <span>{{ info.resourceCode ||'/' }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">位置</span>
        <span>{{ info.location ||'/' }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">任务类型</span>
        <span>{{ info.taskitemTypeName ||'/' }}</span>
      </i-col>
    </Row>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="8">
        <span class="title">作业日期</span>
        <span>{{ info.actionDate ||'/' }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">用途</span>
        <span>{{ info.adTypeName ||'/' }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">客户</span>
        <span>{{ info.advertiserName ||'/' }}</span>
      </i-col>
    </Row>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="8">
        <span class="title">订单档期</span>
        <span>{{ formatSchedule(info.startDate,info.endDate) }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">位置</span>
        <span>{{ info.location ||'/' }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">{{ checkType===1?'质检时间':'对账时间' }}</span>
        <span>{{  checkType===1?(info.qualityTestTime||'/'):(info.settlementTime||'/') }}</span>
      </i-col>
    </Row>
    <Row
      :gutter="8"
      class="m-b-10"
      v-if="checkType===1"
    >
      <i-col span="8">
        <span class="title">发起方</span>
        <span>{{ info.createCompanyName ||'/' }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">供应商</span>
        <span>{{ info.supplierCompanyName ||'/' }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">单品</span>
        <span>{{  info.skuId > -1 ? info.skuName : '/' }}</span>
      </i-col>
    </Row>
    <Row
      :gutter="8"
      class="m-b-10"
      v-if="checkType===2"
    >
      <i-col span="8">
        <span class="title">计费单价</span>
        <span>{{ info.costTypeName }} : </span><span class="p-r-5">{{ formatMoney(info.confirmChargeUnitCost) }}</span><span>/ {{ info.confirmChargeTypeName }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">计费数量</span>
        <span class="p-r-5">{{ info.confirmChargeQuantity }}</span><span>{{ info.confirmChargeTypeName }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">计费金额</span>
        <span>{{ formatMoney(info.confirmCost) }}</span>
      </i-col>
    </Row>
    <Row>
      <i-col span="24">
        <span class="title">备注</span>
        <span class="p-r-10">{{ info.remark}}</span><span>{{ info.extra }}</span>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'
export default {
  props: {
    checkType: { // 类型：1-质检，2-对账
      type: Number,
      default: 1
    },
    info: {
      type: Object
    }
  },
  data () {
    return {

    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    formatSchedule (sDate, eDate) {
      return GetCurrentSchedule(sDate, eDate, false)
    }
  }
}
</script>
